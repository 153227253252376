import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import FonGorod1 from "../../static/png/fon-gorod1.png";
import Header from "../HeaderGl";
import InventaryGl from "../InventaryGl";
import PersGl from "../PersGl";
import GorodNoPers from "./NoPers";
import { getServicesPers } from "../../Api/services";

export default function Gorod(props) {
  const Navigate = useNavigate();
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [pers, setPers] = useState(-1);

  useEffect(() => {
    getServicesPers()
      .then((resp) => {
        setPers(resp?.data?.pers && resp?.data?.pers["id"] ? resp.data.pers : false);
      })
      .catch((error) => {
        setPers(-1);
      });
  }, []);
  
  return (
    <>
      <div className={styles.gorod}>
        <Header />
        {/* <div className={styles.blockImgFonGorod1}></div> */}
        <img src={FonGorod1} className={styles.imgFonGorod1} />
        <div className={styles.content}>
          {pers === -1 ? "load" : pers ? <PersGl /> : <GorodNoPers />}
          <InventaryGl />
        </div>
      </div>
    </>
  );
}

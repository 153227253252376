import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import FonDungeon1 from "../../static/png/fon-dungeon1.png";
import Header from "../HeaderGl";
import InventaryGl from "../InventaryGl";
import PersGl from "../PersGl";

export default function Dungeon(props) {
  const Navigate = useNavigate();
  const [openModalRegister, setOpenModalRegister] = useState(false);

  function lineRender(styles1, styles2, index) {
    const block1 = document.querySelector(`.${styles1}`);
    const block2 = document.querySelector(`.${styles2}`);
    const line = document.querySelector(`#line${index}`);

    if (!block1 || !block2 || !line) {
      return;
    }
    
    const rect1 = block1.getBoundingClientRect();
    const rect2 = block2.getBoundingClientRect();

    let x1 = rect1.left + rect1.width / 2;
    const y1 = rect1.top + rect1.height / 2;
    const x2 = rect2.left + rect2.width / 2;
    const y2 = rect2.top + rect2.height / 2;

    // Получаем ширину вьюпорта
    const viewportWidth = window.innerWidth;

    const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);
    line.style.width = `${(length / viewportWidth) * 100}vw`;

    // Преобразуем x1 и y1 в vw
    const x1InVW = (x1 / viewportWidth) * 100;
    const y1InVW = (y1 / viewportWidth) * 100; // Используем viewportWidth для y1, если вы хотите, чтобы y1 также был в vw

    line.style.transform = `translate(${x1InVW}vw, ${y1InVW}vw) rotate(${angle}deg)`;
  }

  useEffect(() => {
    let lineData = [
      [styles.start, styles.yazeika1],
      [styles.start, styles.yazeika2],
      [styles.start, styles.yazeika3],
      [styles.start, styles.yazeika4],
      [styles.yazeika1, styles.yazeika2],
    ];

    setTimeout(() => {
      lineData.map((item, index) => {
        lineRender(item[0], item[1], index);
      });
    }, 1000);
  }, []);

  function clickDungeon(id) {
    console.log(id);
    Navigate(`/battle/${id}`);
  }

  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.overlayLeft} />
      <div className={styles.overlayRight} />
      <div className={styles.dungeon}>
        <Header />
        <img src={FonDungeon1} className={styles.imgFonGorod1} />
        <div className={styles.content}>
          <div className={styles.map}>
            <div id={"line0"} className={`${styles.line}`}></div>
            <div id={"line1"} className={`${styles.line}`}></div>
            <div id={"line2"} className={`${styles.line}`}></div>
            <div id={"line3"} className={`${styles.line}`}></div>
            <div id={"line4"} className={`${styles.line}`}></div>
            <div id={"line5"} className={`${styles.line}`}></div>
            <div id={"line6"} className={`${styles.line}`}></div>
            <div id={"line7"} className={`${styles.line}`}></div>
            <div id={"line8"} className={`${styles.line}`}></div>
            <div className={`${styles.yazeika} ${styles.start}`}></div>
            <div
              onClick={() => clickDungeon(2)}
              className={`${styles.yazeika} ${styles.yazeika1}`}
            ></div>
            <div
              onClick={() => clickDungeon(3)}
              className={`${styles.yazeika} ${styles.yazeika2}`}
            ></div>
            <div
              onClick={() => clickDungeon(4)}
              className={`${styles.yazeika} ${styles.yazeika3}`}
            ></div>
            <div
              onClick={() => clickDungeon(5)}
              className={`${styles.yazeika} ${styles.yazeika4}`}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

import logo from "./logo.svg";
import "./App.css";
import Pusher from "pusher-js";
import axios from "axios";
import Pages from "./Pages";
import { useEffect } from "react";

// Настройки Pusher
const pusher = new Pusher("b6e353353db7ebe7f6c0", {
  secret: "4f01eb95c51fd8a8cbd8",
  cluster: "mt1",
});

function App() {
  const channel = pusher.subscribe("my-channel");
  channel.bind("my-event", function (data) {
    alert("Received event with data: " + JSON.stringify(data));
  });

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
  }, []);

  function sendEvent() {
    const postData = {
      name: "John Doe",
      email: "john.doe@example.com",
    };

    // // Отправка POST-запроса
    // axios
    //   .post("/test.php", postData, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((response) => {
    //     // Обработка успешного ответа
    //     console.log("Response:", response.data);
    //   })
    //   .catch((error) => {
    //     // Обработка ошибки
    //     console.error("Error:", error);
    //   });
  }

  console.log("test");
  setTimeout(() => {
    // channel.trigger("client-new-message", {
    //   message: "Hello, world!",
    // });
    sendEvent();
  }, 5000);

  return <Pages />;
}

export default App;

import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import FonDungeon1_1 from "../../static/png/fon-dungeon1-1.png";
import Ten1 from "../../static/png/pers/ten/ten1.png";
import PersLovka1 from "../../static/png/pers/lovka1.png";
import Lovka1Icon from "../../static/png/pers/lovka1Icon.png";
import RashodnikFon1Png from "../../static/png/rashodnik/1fon.png";
import BattleHeader from "./Header";
import BattleStats from "./Stats";

export default function Battle(props) {
  const Navigate = useNavigate();
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [hoverCharacter, setHoverCharacter] = useState(false);

  useEffect(() => {}, []);

  function clickDungeon(id) {
    console.log(id);
    Navigate(`/battle/${id}`);
  }

  function eventHoverCharacter(character) {
    setHoverCharacter(character);
  }

  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.overlayLeft} />
      <div className={styles.overlayRight} />
      <div className={styles.dungeon}>
        <BattleHeader />
        <img src={FonDungeon1_1} className={styles.imgFonGorod1} />
        <div className={styles.content}>
          <div className={styles.characterList}>
            <div
              onMouseEnter={() => eventHoverCharacter(true)}
              onMouseLeave={() => eventHoverCharacter(false)}
              className={`${styles.character} ${styles.character1}`}
            >
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
              <div className={styles.status}>
                <div class={styles.poloska}>
                  <span style={{ width: "100%" }}></span>{" "}
                </div>
              </div>
            </div>
            {/* <div className={`${styles.character} ${styles.character2}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character3}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character4}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character5}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character6}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character7}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character8}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character9}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div> */}
            {/* противники(право) */}
            <div
              onMouseEnter={() => eventHoverCharacter(true)}
              onMouseLeave={() => eventHoverCharacter(false)}
              className={`${styles.character} ${styles.character10}`}
            >
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
              <div className={styles.status}>
                <div class={styles.poloska}>
                  <span style={{ width: "25%" }}></span>{" "}
                </div>
              </div>
            </div>
            {/* <div className={`${styles.character} ${styles.character11}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character12}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character13}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character14}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character15}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character16}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character17}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div>
            <div className={`${styles.character} ${styles.character18}`}>
              <img src={PersLovka1} className={`${styles.imgCharacter}`} />
              <img src={Ten1} className={`${styles.ten}`} />
            </div> */}
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.leftInfo}>
            <BattleStats />
            <div className={styles.character}>
              <img className={styles.img} src={Lovka1Icon} />
            </div>
          </div>
          {hoverCharacter && (
            <div className={styles.rightInfo}>
              <BattleStats right={true} />
              <div className={styles.character}>
                <img className={styles.img} src={Lovka1Icon} />
              </div>
            </div>
          )}
          <div className={styles.listSpell}>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.listItemLeft}>
              <div className={styles.yazeika}>
                <img src={RashodnikFon1Png} />
              </div>
              <div className={styles.yazeika}>
                <img src={RashodnikFon1Png} />
              </div>
            </div>
            <div className={styles.listItemRight}>
              <div className={styles.yazeika}>
                <img src={RashodnikFon1Png} />
              </div>
              <div className={styles.yazeika}>
                <img src={RashodnikFon1Png} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

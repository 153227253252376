import Http from "./init";

export const postServicesRegister = (update) => {
  return new Promise(function (resolve, reject) {
    new Http({ isNoAuth: true })
      .post(`api/register.php`, update)
      .then((resp) => {
        return resolve(resp.data);
      })
      .catch((error) => reject(error));
  });
};


export const postServicesLogin = (update) => {
  return new Promise(function (resolve, reject) {
    new Http({ isNoAuth: true })
      .post(`api/login.php`, update)
      .then((resp) => {
        return resolve(resp.data);
      })
      .catch((error) => reject(error));
  });
};



export const getServicesPers = (update) => {
  return new Promise(function (resolve, reject) {
    new Http({ isNoAuth: true })
      .get(`api/pers.php`, update)
      .then((resp) => {
        return resolve(resp.data);
      })
      .catch((error) => reject(error));
  });
};

export const getPersRand = (update) => {
  return new Promise(function (resolve, reject) {
    new Http({ isNoAuth: true })
      .get(`api/persRand.php`, update)
      .then((resp) => {
        return resolve(resp.data);
      })
      .catch((error) => reject(error));
  });
};

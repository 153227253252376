import axios from "axios";

export default class Http {
  constructor(params) {
    this.isNoAuth = params && params.isNoAuth ? false : true;
    this.forLog = params && params.forLog;
    this.instance = axios.create({
      baseURL: "/",
    });
    ////https://dev.admin.zbs.ee/api/v2/ тест
    ////https://dev.admin.zbs.ee/api/v2/ рабочий
    return this.init();
  }
  init() {
    this.instance.interceptors.request.use(
      (request) => {
        request.headers["login"] = localStorage.getItem("login");
        request.headers["uid"] = localStorage.getItem("uid");
        request.headers["language"] = localStorage.getItem("language");
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return this.instance;
  }
}

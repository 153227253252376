import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import PersLovka1 from "../../../static/png/pers/lovka1.png";
import VoprosJpg from "../../../static/png/icon/vopros.jpg";

import Zelovek1Png from "../../../static/png/icon/zelovek1.png";
import Elf1Png from "../../../static/png/icon/elf1.png";
import Dvarf1Png from "../../../static/png/icon/dvarf1.png";
import TooltipCustom from "../../TooltipCustom";
import { opisanieId } from "../../../Helper/text";

export default function CreatePersPers({ pers }) {
  const Navigate = useNavigate();
  const [hover, setHover] = useState(false);
  console.log(opisanieId(pers.opisanie), "asds", pers.opisanie);
  return (
    <>
      <div className={`${styles.pers}`}>
        <div className={styles.container}>
          <div className={styles.odezda}>
            <img src={PersLovka1} className={styles.imgCharacter} />
            <div className={styles.vrazdenka}>
              <div className={styles.item}>
                {pers.rasa == 1 ? (
                  <img
                    onMouseEnter={() => setHover(opisanieId("zelovek"))}
                    onMouseLeave={() => setHover(false)}
                    src={Zelovek1Png}
                  />
                ) : pers.rasa == 2 ? (
                  <img
                    onMouseEnter={() => setHover(opisanieId("elf"))}
                    onMouseLeave={() => setHover(false)}
                    src={Elf1Png}
                  />
                ) : pers.rasa == 3 ? (
                  <img
                    onMouseEnter={() => setHover(opisanieId("dvarf"))}
                    onMouseLeave={() => setHover(false)}
                    src={Dvarf1Png}
                  />
                ) : (
                  ""
                )}
              </div>
              <div
                onMouseEnter={() => setHover(opisanieId(pers.opisanie))}
                onMouseLeave={() => setHover(false)}
                className={styles.item}
              >
                <img src={VoprosJpg} />
              </div>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.text}>{opisanieId(pers.opisanie).text}</div>
          </div>
        </div>
      </div>
      {hover && <TooltipCustom tooltip={hover} />}
    </>
  );
}

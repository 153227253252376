import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import FullScreen from "../../../static/png/web/fullscreen.png";
import Lovka1Icon from "../../../static/png/pers/lovka1Icon.png";

export default function GorodNoPers(props) {
  const Navigate = useNavigate();
  const useLocationuseLocation = useLocation();
  const [openModalRegister, setOpenModalRegister] = useState(false);

  return (
    <>
      <div className={styles.noPers}>
        <div className={styles.text}>
          На данный момент у вас нет персонажа, нажмите ниже кнопку "найти" что
          выбрать для себя персонажа
        </div>
        <div
          onClick={() => {
            Navigate(`/create-pers`);
          }}
          className={styles.btn}
        >
          Найти
        </div>
      </div>
    </>
  );
}

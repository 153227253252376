import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import styles from "./index.module.css";
import SilaPng from "../../static/png/icon/sila.png";
import LovkaPng from "../../static/png/icon/lovka.png";
import IntelektPng from "../../static/png/icon/intelekt.png";

export default function TooltipCustom(props) {
  const useLocationuseLocation = useLocation();
  const Navigate = useNavigate();
  const [tooltipPosition, setTooltipPosition] = useState({
    top: '-100vw',
    left: '-100vw',
  });

  const updateTooltipPosition = (event) => {
    const { clientX, clientY } = event;
    setTooltipPosition({ top: clientY + 5, left: clientX + 20 }); // Смещение на 10 пикселей
  };

  useEffect(() => {
    window.addEventListener("mousemove", updateTooltipPosition);
    return () => {
      window.removeEventListener("mousemove", updateTooltipPosition);
    };
  }, []);

  return ReactDOM.createPortal(
    <div
      style={{
        top: tooltipPosition.top,
        left: tooltipPosition.left,
      }}
      className={styles.tooltipCustom}
    >
      {props.tooltip.title && (
        <div className={styles.title}>{props.tooltip.title}</div>
      )}
      {props.tooltip.bonus && props.tooltip.bonus.length > 0 && (
        <div className={styles.bonusi}>
          {props.tooltip.bonus.map((item) => (
            <div className={styles.item}>
              <div className={styles.name}>
                <img src={item.img} />
                {item.title}:
              </div>
              <div className={styles.number}>{item.text}</div>
            </div>
          ))}
        </div>
      )}
      {props.tooltip.text && (
        <div className={styles.text}>{props.tooltip.text}</div>
      )}
    </div>,
    document.body
  );
}

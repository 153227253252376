import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import FullScreen from "../../../static/png/web/fullscreen.png";
import Lovka1Icon from "../../../static/png/pers/lovka1Icon.png";

export default function BattleHeader(props) {
  const Navigate = useNavigate();
  const useLocationuseLocation = useLocation();
  const [openModalRegister, setOpenModalRegister] = useState(false);
  function fullScreen() {
    var element = document.querySelector("body");
    if (document.fullscreenElement) {
      //закрываем
      document.exitFullscreen();
    } else if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitrequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullscreen) {
      element.mozRequestFullScreen();
    }
  }
  console.log(useLocationuseLocation.pathname.split("/")[1], "asd");
  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}></div>
        <div className={styles.center}>
          <div className={styles.character}>
            <img className={styles.img} src={Lovka1Icon} />
          </div>
          <div className={`${styles.character} ${styles.vrag}`}>
            <img className={styles.img} src={Lovka1Icon} />
          </div>
        </div>
        <div className={styles.right}>
          <img onClick={fullScreen} className={styles.img} src={FullScreen} />
        </div>
      </div>
    </>
  );
}

import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import FullScreen from "../../static/png/web/fullscreen.png";
import Gold from "../../static/png/web/gold.png";

export default function Header(props) {
  const Navigate = useNavigate();
  const useLocationuseLocation = useLocation();
  const [openModalRegister, setOpenModalRegister] = useState(false);
  function fullScreen() {
    var element = document.querySelector("body");
    if (document.fullscreenElement) {
      //закрываем
      document.exitFullscreen();
    } else if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitrequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullscreen) {
      element.mozRequestFullScreen();
    }
  }
  console.log(useLocationuseLocation.pathname.split("/")[1], "asd");
  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.menu}>
            <div className={styles.level}>1</div>
            <div className={styles.yr}>ур</div>
            <div className={styles.title}>Исследования</div>
          </div>
          <div
            onClick={() => Navigate(`/gorod`)}
            className={`${styles.menu} ${
              useLocationuseLocation.pathname.split("/")[1] === "gorod"
                ? styles.active
                : ""
            }`}
          >
            <div className={styles.title}>Персонаж</div>
          </div>
          <div className={`${styles.menu}`}>
            <div className={styles.title}>Таланты</div>
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.block}>
            <img className={styles.img} src={Gold} />
            <div className={styles.number}>0</div>
            <div className={styles.title}>Золото</div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={`${styles.menu}`}>
            <div className={styles.title}>Аукцион</div>
          </div>
          <div className={`${styles.menu}`}>
            <div className={styles.title}>Магазин</div>
          </div>
          <div
            onClick={() => Navigate(`/dungeon`)}
            className={`${styles.menu} ${
              useLocationuseLocation.pathname.split("/")[1] === "dungeon" ||
              useLocationuseLocation.pathname.split("/")[1] === "battle"
                ? styles.active
                : ""
            }`}
          >
            <div className={styles.title}>Подземелье</div>
          </div>
          <img onClick={fullScreen} className={styles.img} src={FullScreen} />
        </div>
      </div>
    </>
  );
}

import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import FonGorod1 from "../../static/png/fon-gorod1.png";
import Header from "../HeaderGl";
import PersGl from "../PersGl";
import CreatePersPers from "./Pers";
import FullScreen from "../../static/png/web/fullscreen.png";
import { getPersRand, getServicesPers } from "../../Api/services";

export default function CreatePers(props) {
  const Navigate = useNavigate();
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [persList, setPersList] = useState(false);

  useEffect(() => {
    getServicesPers()
      .then((resp) => {
        if (resp?.data?.pers && resp?.data?.pers["id"]) {
          //перс есть, выгоняем
          Navigate(`/gorod`);
        } else {
          getPersRand()
            .then((resp) => {
              setPersList(resp.data.pers);
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {});
  }, []);

  function fullScreen() {
    var element = document.querySelector("body");
    if (document.fullscreenElement) {
      //закрываем
      document.exitFullscreen();
    } else if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitrequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullscreen) {
      element.mozRequestFullScreen();
    }
  }

  return (
    <>
      <div className={styles.createPers}>
        <div className={styles.header}>
          <img onClick={fullScreen} className={styles.img} src={FullScreen} />
        </div>
        <img src={FonGorod1} className={styles.imgFonGorod1} />
        <div className={styles.content}>
          <div className={styles.text}>
            Нажмите на подходящего для вас персонажа
          </div>
          <div className={styles.listPers}>
            {persList &&
              persList?.map((pers) => <CreatePersPers pers={pers} />)}
          </div>
          <div className={styles.btn}>Обновить</div>
        </div>
      </div>
    </>
  );
}

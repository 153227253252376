import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import styles from "./index.module.css";
import { postServicesLogin, postServicesRegister } from "../../../Api/services";

export default function MainModalRegister(props) {
  const useLocationuseLocation = useLocation();
  const Navigate = useNavigate();
  const [inputLogin, setInputLogin] = useState("");
  const [inputLoginError, setInputLoginError] = useState("");
  const [inputPass, setInputPass] = useState("");
  const [inputPass2, setInputPass2] = useState("");
  const [inputPassError, setInputPassError] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [registerActive, setRegisterActive] = useState(false);

  useEffect(() => {
    // props.close();
  }, []);

  const clickRegister = (e) => {
    if (
      inputPass.length > 2 &&
      inputPass === inputPass2 &&
      inputLogin.length > 3
    ) {
      postServicesRegister({
        login: inputLogin,
        pass: inputPass,
        email: inputEmail,
      })
        .then((resp) => {
          Navigate(`/gorod`);
        })
        .catch((error) => {});
    }
  };

  const clickAvtorization = (e) => {
    if (inputPass.length > 2 && inputLogin.length > 3) {
      postServicesLogin({
        login: inputLogin,
        pass: inputPass,
      })
        .then((resp) => {
          Navigate(`/gorod`);
          localStorage.setItem("login", resp.data.login);
          localStorage.setItem("uid", resp.data.uid);
        })
        .catch((error) => {
          setInputPass("");
          setInputPassError(true);
          setTimeout(() => {
            setInputPassError(false);
          }, 1000);
        });
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.default}>
      <div className={styles.modal}>
        <div onClick={props.close} className={styles.closeBackground}></div>
        <form className={`${styles.container}`}>
          <div onClick={props.close} className={styles.close}>
            <svg
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="1">
                <path
                  d="M14.5195 23.481L23.4812 14.5193"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.4812 23.481L14.5195 14.5193"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>
          <div className={styles.title}>
            {registerActive ? "Регистрация" : "Авторизация"}
          </div>
          <div className={styles.text}></div>
          <input
            required
            className={`${styles.input} ${inputLoginError ? styles.error : ""}`}
            onChange={(e) => setInputLogin(e.target.value)}
            value={inputLogin}
            placeholder="*логин"
          />
          <input
            required
            className={`${styles.input} ${inputPassError ? styles.error : ""}`}
            onChange={(e) => setInputPass(e.target.value)}
            type={"password"}
            value={inputPass}
            placeholder="*пароль"
          />
          {registerActive ? (
            <>
              <input
                required
                type={"password"}
                className={`${styles.input} ${
                  inputPass.length > 2
                    ? inputPass !== inputPass2 && styles.error
                    : ""
                }`}
                onChange={(e) => setInputPass2(e.target.value)}
                value={inputPass2}
                placeholder="*повторите пароль"
              />
              <input
                required
                type={"email"}
                className={`${styles.input}`}
                onChange={(e) => setInputEmail(e.target.value)}
                value={inputEmail}
                placeholder="email для востановления"
              />
            </>
          ) : (
            ""
          )}
          <div className={styles.btn}>
            <div
              onClick={registerActive ? clickRegister : clickAvtorization}
              className={styles.btnText}
            >
              {registerActive ? "Сохранить" : "Войти"}
            </div>
          </div>
          <div
            onClick={() => {
              setRegisterActive(!registerActive);
            }}
            className={`${styles.btn} ${styles.btnRegistr}`}
          >
            <div className={styles.btnText}>
              {registerActive ? "Авторизация" : "Регистрация"}
            </div>
          </div>
        </form>
      </div>
    </div>,
    document.body
  );
}

import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import MainPage from "./Main";
import GorodPage from "./Gorod";
import DungeonPage from "./Dungeon";
import FonGorod1 from "../static/png/fon-gorod1.png";
import BattlePage from "./Battle";
import CreatePersPage from "./CreatePers";

const pageVariants = {
  initial: {
    opacity: 1,
    x: "100%", // Начальная позиция справа
  },
  in: {
    opacity: 1,
    x: 0, // Конечная позиция
  },
  out: {
    opacity: 1,
    x: "-100%", // Конечная позиция влево
  },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.8,
};

export default function Pages(props) {
  const [modalNotification, setModalNotification] = useState(false);
  const [paymentNotice, setPaymentNotice] = useState(-1);
  const location = useLocation();

  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<MainPage />} />
          <Route
            exact
            path="/gorod"
            element={
              <motion.div
                variants={pageVariants}
                initial="initial"
                animate="in"
                exit="out"
                transition={pageTransition}
              >
                <GorodPage />{" "}
              </motion.div>
            }
          />
          <Route
            exact
            path="/create-pers"
            element={
              <motion.div
                variants={pageVariants}
                initial="initial"
                animate="in"
                exit="out"
                transition={pageTransition}
              >
                <CreatePersPage />{" "}
              </motion.div>
            }
          />
          {/* тут выбираем в какое подземелье нам идти */}
          <Route
            exact
            path="/dungeon"
            element={
              <motion.div
                variants={pageVariants}
                initial="initial"
                animate="in"
                exit="out"
                transition={pageTransition}
              >
                <DungeonPage />
              </motion.div>
            }
          />
          {/* само подземелье (уже было сгенерено) */}
          <Route
            exact
            path="/dungeon/:id"
            element={
              <motion.div
                variants={pageVariants}
                initial="initial"
                animate="in"
                exit="out"
                transition={pageTransition}
              >
                <DungeonPage />
              </motion.div>
            }
          />

          {/* тут битва в поздемелье (id битвы) */}
          <Route
            exact
            path="/battle/:id"
            element={
              <motion.div
                variants={pageVariants}
                initial="initial"
                animate="in"
                exit="out"
                transition={pageTransition}
              >
                <BattlePage />
              </motion.div>
            }
          />
        </Routes>
      </AnimatePresence>

      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: -1,
          background: "#000",
        }}
      ></div>
    </>
  );
}

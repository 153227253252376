import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function InventaryGl(props) {
  const Navigate = useNavigate();
  const [openModalRegister, setOpenModalRegister] = useState(false);

  return (
    <>
      <div className={styles.inventary}>
        <div className={styles.title}>Инвентарь</div>
        <div className={styles.container}>
          <div className={`${styles.block}`}>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
          </div>
          <div className={`${styles.block}`}>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
          </div>
          <div className={`${styles.block}`}>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
          </div>
          <div className={`${styles.block}`}>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
          </div>
          <div className={`${styles.block}`}>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
          </div>
          <div className={`${styles.block}`}>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
          </div>
          <div className={`${styles.block}`}>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
          </div>
          <div className={`${styles.block}`}>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
            <div className={styles.yazeika}></div>
          </div>
        </div>
      </div>
    </>
  );
}

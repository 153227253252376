import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OruzFon1Png from "../../static/png/oruz/1fon.png";
import Poyas1 from "../../static/png/poyas/1.png";
import Rykzak1 from "../../static/png/rykzak/1.png";
import GolovaFon1Png from "../../static/png/golova/1fon.png";
import TeloFon1Png from "../../static/png/telo/1fon.png";
import NogiFon1Png from "../../static/png/nogi/1fon.png";
import PerziFon1Png from "../../static/png/perzi/1fon.png";
import PleziFon1Png from "../../static/png/plezi/1fon.png";
import SchitFon1Png from "../../static/png/schit/1fon.png";
import BotinkiFon1Png from "../../static/png/botinki/1fon.png";
import OzereleFon1Png from "../../static/png/ozerele/1fon.png";
import KolzoFon1Png from "../../static/png/kolzo/1fon.png";
import RashodnikFon1Png from "../../static/png/rashodnik/1fon.png";

import VoprosJpg from "../../static/png/icon/vopros.jpg";
import FizZaschitaPng from "../../static/png/icon/fiz-zaschita.png";
import MagZaschitaPng from "../../static/png/icon/mag-zaschita.png";
import SilaPng from "../../static/png/icon/sila.png";
import LovkaPng from "../../static/png/icon/lovka.png";
import IntelektPng from "../../static/png/icon/intelekt.png";
import HpPng from "../../static/png/icon/hp.png";
import ManaPng from "../../static/png/icon/mana.png";
import AttackPng from "../../static/png/icon/attack.png";
import RegenHpPng from "../../static/png/icon/regen-hp.png";
import RegenManaPng from "../../static/png/icon/regen-mana.png";
import ToznostPng from "../../static/png/icon/toznost.png";
import YvorotPng from "../../static/png/icon/yvorot.png";
import UdazaPng from "../../static/png/icon/udaza.png";
import IniziativaPng from "../../static/png/icon/iniziativa.png";

import PersLovka1 from "../../static/png/pers/lovka1.png";

import Zelovek1Png from "../../static/png/icon/zelovek1.png";
import Dvarf1Png from "../../static/png/icon/dvarf1.png";
import Elf1Png from "../../static/png/icon/elf1.png";

export default function PersGl(props) {
  const Navigate = useNavigate();
  const [openModalRegister, setOpenModalRegister] = useState(false);

  return (
    <>
      <div
        className={`${styles.pers} ${props.container ? props.container : ""}`}
      >
        <div className={styles.container}>
          {!props.createPers && (
            <div className={styles.level}>
              <div className={styles.textLevel}>1</div>
              <div class={styles.poloska}>
                <span style={{ width: "25%" }}></span>{" "}
              </div>
              <div className={styles.xp}>0/10</div>
              <div className={styles.name}>HardskillPRO228</div>
            </div>
          )}
          <div className={styles.odezda}>
            <img src={PersLovka1} className={styles.imgCharacter} />
            <div className={styles.vrazdenka}>
              <div className={styles.vrazdenkaTitle}>Враждённое</div>
              <div className={styles.item}>
                <img src={Zelovek1Png} />
              </div>
              <div className={styles.item}>
                <img src={Dvarf1Png} />
              </div>
              <div className={styles.item}>
                <img src={Elf1Png} />
              </div>
              <div className={`${styles.item} ${styles.noItem}`}>
                <img src={VoprosJpg} />
              </div>
              <div className={`${styles.item} ${styles.noItem}`}>
                <img src={VoprosJpg} />
              </div>
              <div className={`${styles.item} ${styles.noItem}`}>
                <img src={VoprosJpg} />
              </div>
            </div>

            <div className={`${styles.yazeika} ${styles.plezi}`}>
              <img src={PleziFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.ozerele}`}>
              <img src={OzereleFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.oruz1}`}>
              <img src={OruzFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.poyas}`}>
              <img src={Poyas1} />
            </div>
            <div className={`${styles.yazeika} ${styles.golova}`}>
              <img src={GolovaFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.telo}`}>
              <img src={TeloFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.nogi}`}>
              <img src={NogiFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.botinki}`}>
              <img src={BotinkiFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.kolzo1}`}>
              <img src={KolzoFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.oruz2}`}>
              <img src={SchitFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.rykzak}`}>
              <img src={Rykzak1} />
            </div>
            <div className={`${styles.yazeika} ${styles.rashodnik1}`}>
              <img src={RashodnikFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.rashodnik2}`}>
              <img src={RashodnikFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.kolzo2}`}>
              <img src={KolzoFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.perzi}`}>
              <img src={PerziFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.rashodnik3}`}>
              <img src={RashodnikFon1Png} />
            </div>
            <div className={`${styles.yazeika} ${styles.rashodnik4}`}>
              <img src={RashodnikFon1Png} />
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.stati}>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={SilaPng} />
                  Сила:{" "}
                </div>
                <div className={styles.number}>2</div>
              </div>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={LovkaPng} />
                  Ловкость:{" "}
                </div>
                <div className={styles.number}>1</div>
              </div>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={IntelektPng} />
                  Интелект:{" "}
                </div>
                <div className={styles.number}>0</div>
              </div>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={HpPng} />
                  Жизни:{" "}
                </div>
                <div className={styles.number}>40/50</div>
              </div>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={ManaPng} />
                  Мана:{" "}
                </div>
                <div className={styles.number}>0/0</div>
              </div>
            </div>
            <div className={styles.stati}>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={AttackPng} />
                  Атака:{" "}
                </div>
                <div className={styles.number}>5</div>
              </div>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={FizZaschitaPng} />
                  Физ/Защита:{" "}
                </div>
                <div className={styles.number}>0</div>
              </div>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={MagZaschitaPng} />
                  Маг/Защита:{" "}
                </div>
                <div className={styles.number}>0</div>
              </div>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={RegenHpPng} />
                  Рег/Жизни:{" "}
                </div>
                <div className={styles.number}>0</div>
              </div>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={RegenManaPng} />
                  Рег/Мана:{" "}
                </div>
                <div className={styles.number}>0</div>
              </div>
            </div>
            <div className={styles.stati}>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={ToznostPng} />
                  Точность:{" "}
                </div>
                <div className={styles.number}>100%</div>
              </div>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={YvorotPng} />
                  Уворот:{" "}
                </div>
                <div className={styles.number}>0%</div>
              </div>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={UdazaPng} />
                  Удача:{" "}
                </div>
                <div className={styles.number}>0%</div>
              </div>
              <div className={styles.item}>
                <div className={styles.name}>
                  <img src={IniziativaPng} />
                  Инициатива:{" "}
                </div>
                <div className={styles.number}>5</div>
              </div>
            </div>
          </div>
        </div>
        {!props.createPers && (
          <div className={styles.sumka}>
            <div className={styles.title}>Рюкзак</div>
            <div className={styles.scroll}>
              <div className={styles.block}>
                <div className={`${styles.yazeika}`}></div>
                <div className={`${styles.yazeika}`}></div>
                <div className={`${styles.yazeika}`}></div>
                <div className={`${styles.yazeika}`}></div>
                <div className={`${styles.yazeika}`}></div>
                <div className={`${styles.yazeika}`}></div>
                <div className={`${styles.yazeika}`}></div>
                <div className={`${styles.yazeika}`}></div>
                <div className={`${styles.yazeika}`}></div>
                <div className={`${styles.yazeika}`}></div>
                <div className={`${styles.yazeika}`}></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

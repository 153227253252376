import VoprosJpg from "../static/png/icon/vopros.jpg";
import FizZaschitaPng from "../static/png/icon/fiz-zaschita.png";
import MagZaschitaPng from "../static/png/icon/mag-zaschita.png";
import SilaPng from "../static/png/icon/sila.png";
import LovkaPng from "../static/png/icon/lovka.png";
import IntelektPng from "../static/png/icon/intelekt.png";
import HpPng from "../static/png/icon/hp.png";
import ManaPng from "../static/png/icon/mana.png";
import AttackPng from "../static/png/icon/attack.png";
import RegenHpPng from "../static/png/icon/regen-hp.png";
import RegenManaPng from "../static/png/icon/regen-mana.png";
import ToznostPng from "../static/png/icon/toznost.png";
import YvorotPng from "../static/png/icon/yvorot.png";
import UdazaPng from "../static/png/icon/udaza.png";
import IniziativaPng from "../static/png/icon/iniziativa.png";

export const opisanieId = (id) => {
  ////////////////////////РАСЫ
  if (id == "zelovek") {
    return {
      img: "",
      title: "Человек",
      bonus: [
        { img: SilaPng, title: "Сила", text: "+1" },
        { img: LovkaPng, title: "Ловкость", text: "+1" },
        { img: IntelektPng, title: "Интелект", text: "+1" },
        { img: IniziativaPng, title: "Инициатива", text: "5" },
      ],
      text: "Люди — это универсальная раса, обладающая выдающейся адаптивностью и разнообразием, что позволяет им выживать в самых разных условиях.",
    };
  } else if (id == "elf") {
    return {
      img: "",
      title: "Эльф",
      bonus: [
        { img: LovkaPng, title: "Ловкость", text: "+1" },
        { img: SilaPng, title: "Интелект", text: "+1" },
        { img: IniziativaPng, title: "Инициатива", text: "6" },
      ],
      text: "Эльфы — это изящная и долговечная раса, обладающая глубокими связями с природой и магией, что делает их мастерами в искусстве стрельбы из лука и колдовства.",
    };
  } else if (id == "dvarf") {
    return {
      img: "",
      title: "Дварф",
      bonus: [
        { img: SilaPng, title: "Сила", text: "+2" },
        { img: LovkaPng, title: "Ловкость", text: "+1" },
        { img: IniziativaPng, title: "Инициатива", text: "4" },
      ],
      text: "Дварфы — это крепкая и стойкая раса, известная своими мастерскими навыками в кузнечном деле и горном деле, а также непоколебимым духом и преданностью своим традициям.",
    };
  }
  ////////////////////////РАСЫ

  ///////////////////ВРАЖДЁННОЕ/////////////////////
  if (id == "sila_1") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С детства он тренировался, поднимая тяжести и сражаясь с соперниками, чтобы стать сильнейшим в своем крае. Теперь он путешествует по миру, участвуя в турнирах.",
    };
  } else if (id == "sila_2") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "Он вырос в суровых условиях, стал известным воином, защищая свою деревню от врагов. Его мечта — прославиться в великих сражениях и оставить наследие для будущих поколений.",
    };
  } else if (id == "sila_3") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С раннего возраста он проявлял невероятную силу, работая на полях и тренируясь в боевых искусствах. Теперь он странствует по миру, сражаясь с чудовищами и защищая тех, кто не может постоять за себя.",
    };
  } else if (id == "sila_4") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "Он вырос в семье фермеров, с юных лет мечтал стать великим воином. Он отправился в путешествие, чтобы сражаться с врагами и защищать слабых, завоевывая славу и уважение.",
    };
  } else if (id == "sila_5") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С юных лет он мечтал стать воином, вдохновляясь историями о героях и их подвигах. Он тренируется каждый день, изучая боевые искусства и готовясь к приключениям, которые изменят его жизнь.",
    };
  } else if (id == "sila_6") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С детства он мечтал стать великим воином, вдохновляясь рассказами о славных битвах и героях. Он упорно тренируется, изучая искусство боя и готовясь к моменту, когда сможет доказать свою храбрость на поле сражения.",
    };
  } else if (id == "sila_7") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [
        { img: SilaPng, title: "Сила", text: "+1" },
        { img: SilaPng, title: "Ловкость", text: "+1" },
      ],
      text: "С раннего возраста он мечтал стать воином, вдохновляясь подвигами героев из легенд. Каждый день он тренируется, оттачивая свои навыки и готовясь к моменту, когда сможет сразиться за честь и справедливость.",
    };
  } else if (id == "sila_8") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "В маленькой деревне, окруженной лесами и полями, юноша мечтал стать воином, чтобы защитить свою семью и родные земли. Он проводил дни, тренируясь с деревянным мечом и слушая истории старых рыцарей, надеясь однажды получить возможность сразиться за честь и славу.",
    };
  } else if (id == "sila_9") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "В глухом лесу жил молодой человек, который мечтал стать воином, чтобы защитить свою деревню от набегов разбойников. Он изучал природу, обучаясь охоте и выживанию.",
    };
  } else if (id == "sila_10") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "В шумном городе, полном интриг и опасностей, юноша, работал простым слугой в трактире, мечтает стать воином. Он тайно наблюдал за прибывающими рыцарями, записывая их приемы и стратегии, надеясь однажды покинуть трактир и сразиться за свою мечту.",
    };
  } else if (id == "sila_11") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "В шумном городе, полном интриг и опасностей, юноша, работал простым слугой в трактире, мечтает стать воином. Он тайно наблюдал за прибывающими рыцарями, записывая их приемы и стратегии, надеясь однажды покинуть трактир и сразиться за свою мечту.",
    };
  } else if (id == "sila_12") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "Сильный человек, выросший в деревне, стал мастером кузнечного дела, создавая оружие для местных воинов. Он мечтает о великих подвигах и сражениях, стремясь доказать свою мощь.",
    };
  } else if (id == "lovkost_1") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "В шумном городе, обладая выдающейся ловкостью, зарабатывает на жизнь, выступая на ярмарках с акробатическими трюками и фокусами. Он мечтает стать мастером воровства что бы завоевать уважение среди гильдии воров.",
    };
  } else if (id == "lovkost_2") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "В маленьком городке, обладая исключительной ловкостью, тренируется в искусстве фехтования, мечтая стать знаменитым мастером. Он проводит дни, прыгая по крышам и участвуя в уличных соревнованиях, стремясь доказать свою ценность и завоевать уважение.",
    };
  } else if (id == "lovkost_3") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "В маленькой деревне, он тренируется и изучает быстрые-точные удары. Он мечтает стать великим воином, способным уклоняться от атак врагов и наносить удары с невероятной скоростью.",
    };
  } else if (id == "lovkost_4") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "В шумном городе юноша, проводит дни, тренируясь в акробатических приемах и искусстве боя с легким оружием. Он мечтает стать мастером дуэлей, способным уклоняться от ударов противников и наносить быстрые, точные атаки на поле сражения.",
    };
  } else if (id == "lovkost_5") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "В отдаленной деревне юноша, обладая выдающимися навыками стрельбы из лука, проводит дни, тренируясь на мишенях и охотясь на диких животных. Он мечтает стать мастером дальнего боя, что бы убивать врагов с помощью точных выстрелов.",
    };
  } else if (id == "lovkost_6") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С детства он увлекался стрельбой из лука, проводя дни на тренировках и охоте, чтобы развить свою меткость. Теперь, став мастером дальнего боя, он отправляется в поход, чтобы сразиться с врагами.",
    };
  } else if (id == "lovkost_7") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С юных лет он тренировался в стрельбе из лука, мечтая стать величайшим лучником своего времени. Теперь, с точностью и мастерством, он сражается на полях битвы.",
    };
  } else if (id == "lovkost_8") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С раннего возраста он обучался искусству фехтования с одноручным мечом, стремясь стать мастером ближнего боя. Теперь, с ловкостью и уверенностью, он сражается на турнирах.",
    };
  } else if (id == "lovkost_9") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "В юности он наблюдал за рыцарями, сражающимися на турнирах, и был очарован их мастерством с одноручным оружием. После того как его деревня была атакована, он решил обучиться фехтованию.",
    };
  } else if (id == "lovkost_10") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "В юности он наблюдал, как его отец сражается с врагами, используя одноручный меч, и был поражен его мастерством и грацией.",
    };
  } else if (id == "lovkost_11") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С детства он был ловким и проворным, часто участвуя в уличных играх и соревнованиях, где его навыки акробатики восхищали друзей. После он решил использовать свою ловкость в бою, обучаясь военному искусству.",
    };
  } else if (id == "intelekt_1") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С детства он проявлял необычные способности, изучая древние свитки и практикуя заклинания в уединении. После он решил использовать свои магические силы что бы сразиться с врагами, превращая свои знания в мощное оружие.",
    };
  } else if (id == "intelekt_2") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С юных лет он чувствовал в себе магическую силу, изучая заклинания и тайные искусства, чтобы понять природу своей способности. Он решил использовать магию как оружие для сражения с врагами.",
    };
  } else if (id == "intelekt_3") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С раннего возраста он увлекался изучением магии, погружаясь в древние книги и практикуя заклинания в уединении. Когда его наставник был убит в результате магической дуэли, он поклялся использовать свои знания, чтобы отомстить и стать мастером магического сражения.",
    };
  } else if (id == "intelekt_4") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "Он проявляет выдающиеся способности к магии, изучая заклинания и алхимию, чтобы понять силу, скрытую в мире. Он осознал, что его знания могут стать оружием, и решил использовать магию для защиты и сражений.",
    };
  } else if (id == "intelekt_5") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "Он любил погружаться в древние текста и эксперименты с заклинаниями, стремясь постичь тайны силы. После решил применять магию в сражениях, чтобы сражаться с теми, кто злоупотребляет силой.",
    };
  } else if (id == "intelekt_6") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С юных лет он был одержим изучением темной магии, погружаясь в запретные знания и заклинания, которые обещали ему власть. Осознав, что его силы могут подчинить себе других, он решил использовать магию для манипуляции и разрушения.",
    };
  } else if (id == "intelekt_7") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С раннего возраста он стал разбойником, используя хитрость и ловкость, чтобы выжить в жестоком мире. Однажды, найдя древний свиток с заклинаниями, он осознал, что магия может стать его мощным оружием, и решил использовать её, чтобы подчинять своих врагов.",
    };
  } else if (id == "intelekt_8") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "Однажды, обнаружив старую книгу заклинаний, он понял, что магия может дать ему силу, необходимую для управления другими и достижения своих целей.",
    };
  } else if (id == "intelekt_9") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "Однажды, наткнувшись на древний артефакт, он открыл для себя магию и понял, что с её помощью сможет не только грабить, но и подчинять себе своих врагов.",
    };
  } else if (id == "intelekt_10") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "С раннего возраста, окруженный роскошью и привилегиями, он погружался в изучение магии, стремясь расширить свои знания и власть. Осознав, что его богатство может быть использовано для приобретения древних артефактов, он решил применить магию в сражениях.",
    };
  } else if (id == "intelekt_11") {
    return {
      img: "",
      title: "Участник турниров",
      bonus: [{ img: SilaPng, title: "Сила", text: "+1" }],
      text: "Будучи рабом, он проводил дни в тяжелом труде, мечтая о свободе и силе, которые могли бы изменить его судьбу. Однажды, найдя старую книгу заклинаний среди забытых вещей, он начал изучать магию, решив использовать её, чтобы освободиться и отомстить своим угнетателям.",
    };
  }

  /////lovkost_2 нужена одноручка
  /////lovkost_5 нужен лук
  /////lovkost_6 нужен лук
  /////lovkost_7 нужен лук
  /////lovkost_8 нужена одноручка
  /////lovkost_9 нужена одноручка
  /////lovkost_10 нужена одноручка
  ///////////////////ВРАЖДЁННОЕ/////////////////////
};

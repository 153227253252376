import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import FonMain1 from "../../static/png/fon-main1.png";
import MainModalRegister from "./Modal";

export default function Main(props) {
  const Navigate = useNavigate();
  const [openModalRegister, setOpenModalRegister] = useState(false);





  return (
    <>
      <div className={styles.main}>
        <div className={styles.background}></div>
        <img src={FonMain1} className={styles.img} />
        <div className={styles.title}>Героический Рогалик</div>
        <div className={styles.text}>ОНЛАЙН</div>
        <div onClick={() => setOpenModalRegister(true)} className={styles.btn}>
          <div className={styles.btnText}>НАЧАТЬ</div>
        </div>
      </div>
      {openModalRegister ? (
        <MainModalRegister close={() => setOpenModalRegister(false)} />
      ) : (
        ""
      )}
    </>
  );
}

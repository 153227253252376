import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";

import Oruz1 from "../../../static/png/oruz/1.png";
import Poyas1 from "../../../static/png/poyas/1.png";
import Rykzak1 from "../../../static/png/rykzak/1.png";
import SilaPng from "../../../static/png/icon/sila.png";
import LovkaPng from "../../../static/png/icon/lovka.png";
import IntelektPng from "../../../static/png/icon/intelekt.png";
import HpPng from "../../../static/png/icon/hp.png";
import ManaPng from "../../../static/png/icon/mana.png";
import AttackPng from "../../../static/png/icon/attack.png";
import FizZaschitaPng from "../../../static/png/icon/fiz-zaschita.png";
import MagZaschitaPng from "../../../static/png/icon/mag-zaschita.png";
import RegenHpPng from "../../../static/png/icon/regen-hp.png";
import RegenManaPng from "../../../static/png/icon/regen-mana.png";
import ToznostPng from "../../../static/png/icon/toznost.png";
import YvorotPng from "../../../static/png/icon/yvorot.png";
import UdazaPng from "../../../static/png/icon/udaza.png";
import IniziativaPng from "../../../static/png/icon/iniziativa.png";

export default function BattleStats(props) {
  const Navigate = useNavigate();
  const useLocationuseLocation = useLocation();
  const [openModalRegister, setOpenModalRegister] = useState(false);


  return (
    <>
      <div className={`${styles.stats} ${props.right ? styles.rigth : ""}`}>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={SilaPng} />
          </div>
          <div className={styles.number}>2</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={LovkaPng} />
          </div>
          <div className={styles.number}>1</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={IntelektPng} />
          </div>
          <div className={styles.number}>0</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={HpPng} />
          </div>
          <div className={styles.number}>40/50</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={ManaPng} />
          </div>
          <div className={styles.number}>0/0</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={AttackPng} />
          </div>
          <div className={styles.number}>5</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={FizZaschitaPng} />
          </div>
          <div className={styles.number}>0</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={MagZaschitaPng} />
          </div>
          <div className={styles.number}>0</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={RegenHpPng} />
          </div>
          <div className={styles.number}>0</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={RegenManaPng} />
          </div>
          <div className={styles.number}>0</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={ToznostPng} />
          </div>
          <div className={styles.number}>100%</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={YvorotPng} />
          </div>
          <div className={styles.number}>0%</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={UdazaPng} />
          </div>
          <div className={styles.number}>0%</div>
        </div>
        <div className={styles.item}>
          <div className={styles.name}>
            <img src={IniziativaPng} />
          </div>
          <div className={styles.number}>5</div>
        </div>
      </div>
    </>
  );
}
